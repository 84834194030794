import React, { useContext, useState } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaTrash } from "react-icons/fa";

import CartContext from "../../context/CartContext";
import "./CartItem.scss";

const CartItem = ({ product, setShowCart }) => {
  const { removeProduct, updateQuantity } = useContext(CartContext);
  const [qty, setQty] = useState(product.Qty);

  const updateProductQuantity = (value) => {
    if (!value || isNaN(value)) {
      value = 1;
    }

    let qty = parseInt(value, 10);

    if (qty > 150) {
      qty = 150;
    }

    if (qty === 0) {
      return removeProduct(product);
    }

    setQty(qty);
    updateQuantity(product, qty);
  };

  return (
    <div className="cart-item">
      <div className="cart-item__img">
        <Link to={product.Slug} onClick={() => setShowCart(false)}>
          <GatsbyImage alt="Product image" image={product.Images.localFiles[0].childImageSharp.gatsbyImageData} />
        </Link>
      </div>

      <div className="cart-item__content">
        <div className="cart-item__top-content">
          <span className="cart-item__name">{product.Name}</span>
          <span className="remove-btn" title="Ta bort" onClick={() => removeProduct(product)}>
            <FaTrash />
          </span>
        </div>
        <div className="cart-item__info">
          <div className="cart-item__size">
            {product.Measure === "cm" && (
              <>
                {product.SelectedSize} {product.Measure}
              </>
            )}
          </div>
          <div className="cart-item__qty">
            <button className="cart-item__qty-btn" onClick={() => updateProductQuantity(product.Qty - 1)}>
              -
            </button>
            <span className="cart-item__qty-amount cart-item__qty-amount--desktop">
              <input type="text" value={qty} pattern="[0-9]*" min="1" max="150" title="Antal" onChange={(e) => updateProductQuantity(e.target.value)} /> st
            </span>
            <span className="cart-item__qty-amount cart-item__qty-amount--mobile">
              <span style={{ display: "inline-block", width: "30px" }}>{product.Qty}</span>
            </span>
            <button className="cart-item__qty-btn" onClick={() => updateProductQuantity(product.Qty + 1)}>
              +
            </button>
          </div>
          <div className={`cart-item__price ${product.IsSalePrice ? "cart-item__price--sale" : ""}`}> {product.Price * product.Qty} kr </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
