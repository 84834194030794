import React from 'react'
import { FaCheck } from 'react-icons/fa'

export default () => (
  <>
    <FaCheck style={{ marginRight: '5px' }} />
    <span>Fri frakt </span>
    &nbsp;
    <FaCheck style={{ marginLeft: '2px', marginRight: '5px' }} />
    <span>Snabb leverans</span>
  </>
)
