import React, { useRef, useEffect } from "react";

function useClickOutside(ref, callback) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        callback();
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

function ClickOutside(props) {
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, props.callback);

  return <div ref={wrapperRef}>{props.children}</div>;
}

export default ClickOutside;
