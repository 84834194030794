import React from 'react'
import { Link } from 'gatsby'
import ClickOutside from '../ClickOutside'
import CartItem from './CartItem'
import './CartList.scss'
import { Button, Box } from 'react-bulma-components';

const CartList = ({ products, setShowCart }) => (
  <ClickOutside callback={() => setShowCart(false)}>
    <Box className="cart-list">
      {products.length === 0 ? 'Din kundvagn är tom' : products.map((p, i) => <CartItem product={p} setShowCart={setShowCart} key={i} />)}
      <div className="cart-summary">
        <span>Summa:</span>
        <span className="cart-summary__sum">{products.length > 0 ? products.reduce((totalSum, product) => (totalSum += product.Price * product.Qty), 0) : 0} kr</span>
      </div>
      <div className="cart-summary__buttons">
        <div>
          <Button className="wallang-btn" onClick={() => setShowCart(false)}>
            Stäng
          </Button>
        </div>
        <div>
          <Link to="/checkout">
            <Button className="wallang-btn" disabled={products.length <= 0} onClick={() => setShowCart(false)}>
              Till kassan
            </Button>
          </Link>
        </div>
      </div>
    </Box>
  </ClickOutside>
)

export default CartList
