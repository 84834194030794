import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Footer, Heading } from "react-bulma-components";

import { FaFacebookSquare, FaEnvelopeSquare } from "react-icons/fa";

import "./Footer.scss";

export default () => {
  return (
    <Footer className="footer-wrapper">
      <div className="footer-group">
        <Link to="/">
          <div className="footer-logo-wrapper">
            <StaticImage alt="Wallang footer logo" src="../../images/wallang-logo-transparent.png" placeholder="tracedSVG" />
          </div>
        </Link>
      </div>

      <div className="footer-group">
        <Heading renderAs="h2" size={5}>
          Kontakt
        </Heading>
        <ul className="footer-links footer-links--icons">
          <li>
            <a href="mailto:info@wallang.se">
              <FaEnvelopeSquare />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/wallang.se">
              <FaFacebookSquare />
            </a>
          </li>
        </ul>
      </div>

      <div className="footer-group">
        <Heading renderAs="h2" size={5}>
          Information
        </Heading>
        <ul className="footer-links">
          <li>
            <Link to="/om-wallang">Om Wallang</Link>
          </li>
          <li>
            <Link to="/faq">Vanliga frågor</Link>
          </li>
          <li>
            <Link to="/terms-and-conditions">Köpvillkor</Link>
          </li>
          <li>
            <Link to="/personuppgifter">Personuppgifter</Link>
          </li>
        </ul>
      </div>
    </Footer>
  );
};
