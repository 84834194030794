import React from "react";
import { Link } from "gatsby";
import CookieConsent from "react-cookie-consent";

import Footer from "../Footer";
import Navigation from "../Navigation";

import { Container } from "react-bulma-components";

import "./Layout.scss";

const Layout = ({ children }) => (
  <div>
    <Container className="nav-wrapper">
      <Navigation />
    </Container>
    <Container className="content-wrapper">{children}</Container>
    <Footer />
    <CookieConsent buttonText="Jag godkänner" containerClasses="cookie-consent" buttonClasses="cookie-consent__button" contentClasses="cookie-consent__content">
      Vi använder oss av cookies för att ge dig en så bra upplevelse som möjligt.{" "}
      <Link style={{ color: "#fff", textDecoration: "underline" }} to="/terms-and-conditions">
        Läs mer
      </Link>
    </CookieConsent>
  </div>
);

export default Layout;
