import React, { createContext } from 'react'

const CartContext = createContext({
  products: [],
  addProduct: () => {},
  removeProduct: () => {},
  updateQuantity: () => {},
  clearCart: () => {}
})

export class CartProvider extends React.Component {
  addProduct = product => {
    // Check if product exists in cart
    let currentProduct = this.state.products.filter(p => p.Id === product.Id && p.SelectedSize === product.SelectedSize)[0]
    if (currentProduct) {
      return this.updateQuantity(product, currentProduct.Qty + 1)
    }

    const newProduct = {
      Id: product.Id,
      Name: product.Name,
      Images: product.Images,
      Price: product.SalePrice || product.Price,
      SalePrice: product.SalePrice,
      IsSalePrice: product.SalePrice !== null,
      Slug: product.Slug,
      SelectedSize: product.SelectedSize,
      Measure: product.Measure,
      Type: product.Type,
      Qty: 1
    }

    const updatedProducts = [...this.state.products, newProduct]

    this.setState({ products: updatedProducts })
    localStorage.setItem('cart', JSON.stringify(updatedProducts))
  }

  removeProduct = product => {
    const updatedProducts = this.state.products.filter(p => {
      if (p.Id === product.Id) {
        if (p.SelectedSize === product.SelectedSize) {
          return false
        }
      }

      return true
    })

    this.setState({ products: updatedProducts })
    localStorage.setItem('cart', JSON.stringify(updatedProducts))
  }

  updateQuantity = (product, qty) => {
    if (qty === 0) {
      return this.removeProduct(product)
    }

    const updatedProducts = [...this.state.products]
    updatedProducts.forEach(p => {
      if (p.Id === product.Id && p.SelectedSize === product.SelectedSize) {
        p.Qty = qty
      }
    })

    this.setState({ products: updatedProducts })
    localStorage.setItem('cart', JSON.stringify(updatedProducts))
  }

  clearCart = () => {
    this.setState({ products: [] })
    localStorage.removeItem('cart')
  }

  componentDidMount() {
    this.setState({ products: JSON.parse(localStorage.getItem('cart')) || [] })
  }

  state = {
    products: [],
    addProduct: this.addProduct,
    removeProduct: this.removeProduct,
    updateQuantity: this.updateQuantity,
    clearCart: this.clearCart
  }

  render() {
    return <CartContext.Provider value={this.state}>{this.props.children}</CartContext.Provider>
  }
}

export default CartContext
