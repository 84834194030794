import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Cart from "../Cart";
import ShippingInfo from "../ShippingInfo";

import "../../styles/style.scss";
import "./Navigation.scss";

export default () => {
  return (
    <div className="wallang-nav">
      <Link to="/">
        <div className="navbar-logo-wrapper">
          <StaticImage className="navbar-logo" alt="Wallang logo" src="../../images/wallang-logo.jpeg" placeholder="tracedSVG" />
        </div>
      </Link>

      <div className="nav-info">
        <ShippingInfo />
      </div>

      <Cart />
    </div>
  );
};
