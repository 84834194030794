import React, { useState, useContext } from 'react'
import CartList from './CartList'
import CartContext from '../../context/CartContext'
import CartIcon from '../../images/cart.svg'

import './Cart.scss'

const Cart = () => {
  const [showCart, setShowCart] = useState(false)
  const { products, removeProduct } = useContext(CartContext)
  const cartClick = () => {
    if (!showCart) {
      setShowCart(true)
    }
  }

  return (
    <div>
      <span className="cart-title" onClick={() => cartClick()}>
        <img src={CartIcon} alt="cart icon" className="cart-icon" /> Kundvagn{' '}
        {products.length > 0 && <span className="cart-count-badge">{products.reduce((sum, p) => sum + p.Qty, 0)}</span>}
      </span>
      {showCart && <CartList products={products} removeProduct={removeProduct} setShowCart={setShowCart} />}
    </div>
  )
}

export default Cart
